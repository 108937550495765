import MultitagsFilter  from "./multitags_filter";
/**
 * Handy functions to project lat/lng to pixel
 * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
 **/
function project(latLng) {
  var TILE_SIZE = 256

  var siny = Math.sin((latLng.lat() * Math.PI) / 180)

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999)

  return new google.maps.Point(
    TILE_SIZE * (0.5 + latLng.lng() / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI))
  )
}

/**
 * Handy functions to project lat/lng to pixel
 * Extracted from: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
 **/
function getPixel(latLng, zoom) {
  var scale = 1 << zoom
  var worldCoordinate = project(latLng)
  return new google.maps.Point(
    Math.floor(worldCoordinate.x * scale),
    Math.floor(worldCoordinate.y * scale)
  )
}

/**
 * Given a map, return the map dimension (width and height)
 * in pixels.
 **/
function getMapDimenInPixels(map) {
  var zoom = map.getZoom()
  var bounds = map.getBounds()
  var southWestPixel = getPixel(bounds.getSouthWest(), zoom)
  var northEastPixel = getPixel(bounds.getNorthEast(), zoom)
  return {
    width: Math.abs(southWestPixel.x - northEastPixel.x),
    height: Math.abs(southWestPixel.y - northEastPixel.y),
  }
}

/**
 * Given a map and a destLatLng returns true if calling
 * map.panTo(destLatLng) will be smoothly animated or false
 * otherwise.
 *
 * optionalZoomLevel can be optionally be provided and if so
 * returns true if map.panTo(destLatLng) would be smoothly animated
 * at optionalZoomLevel.
 **/
function willAnimatePanTo(map, destLatLng, optionalZoomLevel) {
  var dimen = getMapDimenInPixels(map)

  var mapCenter = map.getCenter()
  optionalZoomLevel = !!optionalZoomLevel ? optionalZoomLevel : map.getZoom()

  var destPixel = getPixel(destLatLng, optionalZoomLevel)
  var mapPixel = getPixel(mapCenter, optionalZoomLevel)
  var diffX = Math.abs(destPixel.x - mapPixel.x)
  var diffY = Math.abs(destPixel.y - mapPixel.y)

  return diffX < dimen.width && diffY < dimen.height
}

/**
 * Returns the optimal zoom value when animating
 * the zoom out.
 *
 * The maximum change will be currentZoom - 3.
 * Changing the zoom with a difference greater than
 * 3 levels will cause the map to "jump" and not
 * smoothly animate.
 *
 * Unfortunately the magical number "3" was empirically
 * determined as we could not find any official docs
 * about it.
 **/
function getOptimalZoomOut(map, latLng, currentZoom) {
  if (willAnimatePanTo(map, latLng, currentZoom)) {
    return currentZoom
  } else if (willAnimatePanTo(map, latLng, currentZoom - 1)) {
    return currentZoom - 1
  } else if (willAnimatePanTo(map, latLng, currentZoom - 2)) {
    return currentZoom - 2
  } else {
    return currentZoom - 3
  }
}

/**
 * Given a map and a destLatLng, smoothly animates the map center to
 * destLatLng by zooming out until distance (in pixels) between map center
 * and destLatLng are less than map width and height, then panTo to destLatLng
 * and finally animate to restore the initial zoom.
 *
 * optionalAnimationEndCallback can be optionally be provided and if so
 * it will be called when the animation ends
 **/
function smoothlyAnimatePanToWorkarround(
  map,
  destLatLng,
  optionalAnimationEndCallback
) {
  var initialZoom = map.getZoom(),
    listener
  var zoomCount = 0

  function zoomIn() {
    if (map.getZoom() < 5 || map.getZoom() < initialZoom) {
      map.setZoom(Math.min(map.getZoom() + 3, Math.max(initialZoom, 7)))
    } else {
      google.maps.event.removeListener(listener)

      //here you should (re?)enable only the ui controls that make sense to your app
      map.setOptions({
        draggable: true,
        zoomControl: true,
        scrollwheel: false,
        disableDoubleClickZoom: false,
      })

      if (!!optionalAnimationEndCallback) {
        optionalAnimationEndCallback()
      }
    }
  }

  function zoomOut() {
    if (willAnimatePanTo(map, destLatLng) || zoomCount > 5) {
      google.maps.event.removeListener(listener)
      listener = google.maps.event.addListener(map, "idle", zoomIn)
      map.panTo(destLatLng)
    } else {
      zoomCount++
      map.setZoom(
        Math.max(getOptimalZoomOut(map, destLatLng, map.getZoom()), 1)
      )
    }
  }

  //here you should disable all the ui controls that your app uses
  map.setOptions({
    draggable: false,
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
  })

  map.setZoom(getOptimalZoomOut(map, destLatLng, initialZoom))
  listener = google.maps.event.addListener(map, "idle", zoomOut)
}

function smoothlyAnimatePanTo(map, destLatLng) {
  if (willAnimatePanTo(map, destLatLng)) {
    map.panTo(destLatLng)
  } else {
    smoothlyAnimatePanToWorkarround(map, destLatLng)
  }
}

function truncate(text, maxLength = 25) {
  text = (text || "").split(" ")
  return (text.length > maxLength
    ? [...text.slice(0, maxLength), "..."]
    : text
  ).join(" ")
}

export default class LetterMap {
  constructor(mapel, issueFilterEl, sites, filterSelected, filterSelectEl) {
    this.sites = JSON.parse(sites)
    this.mapel = mapel;
    this.issueFilterEl = issueFilterEl;
    this.filterSelectEl = filterSelectEl;
    this.selectedTags = null;
    this.init()
    this.showSites(this.sites)
    if (this.issueFilterEl) {
      this.filter = new MultitagsFilter(
        filterSelected,
        this.filterSelectEl, 
        (selected) => this.selectedFilterChanged(selected)
      )
    }
  }

  init() {
    this.map = new google.maps.Map(this.mapel, {
      scrollwheel: false,
      center: { lat: 48.215061544592245, lng: -111.70474575000003 },
      zoom: 3,
      disableDefaultUI: false
    })
    this.initIssueFilterButton()
  }

  initIssueFilterButton() {
    if (this.issueFilterEl) {
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.issueFilterEl)
    }
  }
  
  openRandomLetter(currentSite) {
    const randomSite = this.filteredSites[Math.floor(Math.random() * this.filteredSites.length)]
    if (currentSite == randomSite) {
      // openRandomLetter(currentSite)
      return
    }
    randomSite.letterIndex = Math.floor(Math.random() * (randomSite.filteredLetters ? randomSite.filteredLetters.length : randomSite.lc))
    this.openInfoWindow(randomSite, true)
  }

  openInfoWindow(site, random = false, animate = true) {
    if (site.letters && site.letters.length > 0) {
      this.actuallyOpenInfoWindow(site, random, animate)
    } else {
      $.get(site.lu, (letters) => {
        site.letters = letters
        this.filterLetters(site)
        this.actuallyOpenInfoWindow(site, random, animate)
      })
    }
  }

  actuallyOpenInfoWindow(site, random = false, animate = true) {
    const letter = site.filteredLetters[site.letterIndex]
    if (!letter) {
      this.openRandomLetter(site)
      return
    }

    const $letter = $(
      `
      <div class="infowindow">
        <a href="${letter.url}" class="LetterCard">
          ${
            letter.thumbnail
              ? '<div class="LetterCard-image" style="margin-bottom:10px;background-image:url(\'' +
                letter.thumbnail +
                "')\"></div>"
              : ""
          }
          <div class="LetterCard-body">
            <h5 class="LetterCard-title">
              <span>${letter.title}</span>
            </h5>
            <p class="LetterCard-summary">
              ${truncate(letter.summary)}
            </p>
            <div class="LetterCard-meta">` +
        (window.instance_config.enable_author_name
          ? `<strong>${letter.name}</strong><br>`
          : "") +
        `<span>${site.sl}</span>
          </div>
          </div>
        </a>
      </div>
    `
    )

    const $header = $(`
      <div class="infowindow-header">
        <span>${site.sn}</span>
        <a href="${site.su}" class="pretty-link color-muted">Browse all &rarr;</a>
      </div>
    `)

    const $footer = $(`
      <div class="infowindow-footer">
      </div>
    `)

    $footer.append(
      $("<a>Previous</a>").on("click", (e) => {
        e.preventDefault()
        this.clearRandomTimeout()
        if (site.letterIndex == 0) {
          site.letterIndex = site.letters.length - 1
        } else {
          site.letterIndex--
        }
        this.openInfoWindow(site)
      })
    )
    $footer.append(
      $(`<span>${site.letterIndex + 1} of ${site.filteredLetters.length}</span>`)
    )
    $footer.append(
      $("<a>Next</a>").on("click", (e) => {
        e.preventDefault()
        this.clearRandomTimeout()
        if (site.letterIndex == site.letters.length - 1) {
          site.letterIndex = 0
        } else {
          site.letterIndex++
        }
        this.openInfoWindow(site)
      })
    )

    // this.map.panTo(site.marker.getPosition())
    // this.map.setZoom(7)

    this.infowindow.close()
    this.infowindow.setContent($letter.prepend($header).append($footer)[0])
    //infowindow.setOptions({ disableAutoPan: true })

    const doOpen = () => {
      this.infowindow.open(this.map, site.marker)
      if (random) {
        this.randomTimeout = setTimeout(() => {
          this.openRandomLetter(site)
        }, 6500)
      }
    }

    if (animate) {
      smoothlyAnimatePanToWorkarround(
        this.map,
        site.marker.getPosition(),
        doOpen
      )
    } else {
      doOpen()
    }
  }

  clearRandomTimeout() {
    if (this.randomTimeout) {
      clearTimeout(this.randomTimeout)
      this.randomTimeout = null
    }
  }

  showSites(sites) {
    if (sites.length === 0) {
      // this.mapel.closest("section").hidden = true
      return
    }

    const icon = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "#c60000",
      fillOpacity: 1,
      strokeColor: "#fff",
      strokeWeight: 0,
      scale: 5,
    }

    this.infowindow = new google.maps.InfoWindow()
    const bounds = new google.maps.LatLngBounds()
    let validSites = sites.filter(site => !isNaN(parseFloat(site.sc[0])) && !isNaN(parseFloat(site.sc[1])))
    this.validSites = validSites;
    this.filteredSites = validSites;
  
    for (let site of this.filteredSites) {
      site.letterIndex = 0
      site.marker = new google.maps.Marker({
        position: {
          lat: parseFloat(site.sc[0]),
          lng: parseFloat(site.sc[1]),
        },
        map: this.map,
        title: site.sn,
        icon: icon,
      })

      bounds.extend(site.marker.getPosition())

      google.maps.event.addListener(
        site.marker,
        "click",
        ((site) => {
          return () => {
            this.clearRandomTimeout()
            this.openInfoWindow(site, false, false)
          }
        })(site)
      )
    }

    if (this.filteredSites.length > 1) {
      this.map.fitBounds(bounds, 32)

      google.maps.event.addListenerOnce(this.map, "bounds_changed", () => {
        this.map.setZoom(this.map.getZoom())
      })

      google.maps.event.addListener(this.map, "click", (event) => {
        this.clearRandomTimeout()
        this.infowindow.close()
      })

      google.maps.event.addListener(this.map, "dragstart", (event) => {
        this.clearRandomTimeout()
      })

      google.maps.event.addListener(this.map, "zoomend", (event) => {
        this.clearRandomTimeout()
      })

      google.maps.event.addListenerOnce(this.map, "tilesloaded",  (
        event
      ) => {
        this.openRandomLetter()
      })
    }
  }

  updateMarkers() {
    let visible = {}
    for (let site of this.filteredSites) {
      visible[site.su] = true
    }
    for (let site of this.sites) {
      if (!visible[site.su]) {
        site.marker.setMap(null)
      } else {
        site.marker.setMap(this.map)
      }
    }
  }

  filterLetters(site) {
    if (this.selectedTags) {
      site.filteredLetters = site.letters.filter(letter => this.selectedTags.some(tag => letter.tags.includes(tag)))
    } else {
      site.filteredLetters = site.letters
    }
  }

  filterLettersByTags() {
    for (let site of this.sites) {
      if (site.letters != null) {
        this.filterLetters(site)
      }
    }
  }

  filterTags(tags) {
    this.selectedTags = tags
   
    if (tags.length === 0) {
      this.selectedTags = null
      this.filteredSites = this.validSites
    } else {
      this.filteredSites = this.validSites.filter(site => tags.some(tag => site['tg'].includes(tag)))
      this.selectedTags = tags
    }
    this.filterLettersByTags()
    this.updateMarkers()
    this.clearRandomTimeout()
    this.openRandomLetter()
  }

  selectedFilterChanged(selected) {
    this.filterTags(selected)
  }
};
