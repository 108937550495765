import "vendor/redactor"

up.compiler("[data-wysiwyg]", function (el) {
  $R(el, {
    plugins: [
      el.dataset.hideCounter == "true" ? null : "counter",
      el.dataset.allowRedacting == "true" ? "redacted" : null,
    ].filter(String),
    source: el.dataset.redactorSource == "false" ? false : { codemirror: true },
    stylesClass: "rich-text p-4",
    formattingAdd: {
      "text-gradient": {
        title: '<span class="text-gradient">Gradient</span>',
        api: "module.inline.format",
        args: {
          tag: "span",
          attr: {
            class: "text-gradient",
          },
        },
      },
    },
    imageUpload: el.dataset.imageUpload,
    toolbarFixedTopOffset: 80,
    autosave: el.dataset.autosave,
    autosaveData: {
      authenticity_token: $("meta[name='csrf-token']").attr("content"),
      _method: "patch",
    },
    callbacks: {
      autosave: function(name, data, response)
      {
        $('#submit-actions').replaceWith($(response).find('#submit-actions'));
      }
    },
  })
})
