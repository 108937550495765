;(function ($R) {
  $R.add("plugin", "redacted", {
    translations: {
      en: {
        redact: "Redact",
      },
    },
    init: function (app) {
      this.app = app
      this.lang = app.lang
      this.inline = app.inline
      this.toolbar = app.toolbar
    },
    // public
    start: function () {
      var $button = this.toolbar.addButton("redacted", {
        title: this.lang.get("redact"),
        api: "plugin.redacted.toggle",
      })
      $button.setIcon('<i class="re-icon-fontcolor"></i>')
    },
    toggle: function (size) {
      var args = {
        tag: "span",
        class: "redacted",
        type: "toggle",
      }

      this.inline.format(args)
    },
  })
})(Redactor)
