import "unpoly"
import "unpoly/unpoly.css"
// import "unpoly/unpoly-bootstrap5"
// import "unpoly/unpoly-bootstrap5.css"
import "unpoly/unpoly-migrate"

up.proxy.config.slowDelay = 1000;
up.motion.config.duration = 150;

up.compiler("[up-slow]", element => {
  const toggleClass = (state) => {
    element.classList.toggle("up-slow", state)
  };

  return [
    up.on("up:proxy:slow", () => toggleClass(true)),
    up.on("up:proxy:recover", () => toggleClass(false))
  ];
});
