const handleImageUpload = (input) => {
  up.emit("up:proxy:slow");
  let uploadProgress = 0;
  const { presignUrl, submitUrl, cloudfrontHost } = input.dataset;
  const file = input.files[0];
  const fileName = new Date().getTime() + "-" + file.name
  const image = new Image()
  const reader = new FileReader()
  reader.onload = (e) => {
    image.src = e.target.result
    image.onload = () => {
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0);

      var MAX_WIDTH = 760 * 2;
      var MAX_HEIGHT = 20000;
      var width = image.width;
      var height = image.height;

      if (width == height) {
        width = MAX_WIDTH
        height = MAX_WIDTH
      } else if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width
      canvas.height = height
      var ctx = canvas.getContext("2d")
      ctx.drawImage(image, 0, 0, width, height)
      
      canvas.toBlob(blob => {
        $.ajax(presignUrl + `?filename=${fileName}`, { method: "get" }).then(presign => {
          const data = new FormData()
          for (var key in presign.fields) {
            data.append(key, presign.fields[key])
          }
          data.append('file', blob)

          const xhr = new XMLHttpRequest()
          xhr.onload = () => {
            up.replace("#content_blocks:after", submitUrl, {
              method: "post",
              params: {
                "content_block[content_type]": "image",
                "content_block[content]": `<img width="${width}" height="${height}" src="${cloudfrontHost}/${presign.fields.key}" alt="">`
              },
              reveal: true
            })
            up.emit("up:proxy:recover");
          }
          xhr.upload.addEventListener('progress', (evt) => {
            if (evt.lengthComputable) {
              uploadProgress = (evt.loaded / evt.total) * 100
            }
          }, false)
          xhr.onreadystatechange = (e) => {}
          xhr.open('POST', presign.url)
          xhr.send(data)
        })
      })
    }
  }

  reader.readAsDataURL(file)
}

up.compiler("[data-image]", el => {
  el.addEventListener("change", (event) => {
    handleImageUpload(event.target);
  })
  return () => {
    el.removeEventListener("change")
  }
})