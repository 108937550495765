export default class MultitagsFilter {
  constructor(selected, selectElement, onSelectChanged) {
    this.selected = selected;
    this.options = [];
    this.loadOptions(selectElement);
    this.onSelectChanged = onSelectChanged;
  }

  selectedValues(){
    return this.selected.map((option)=>{
        return this.options[option].value;
    })
  }

  loadOptions(optionsEl) {
    const {options} = optionsEl;
    for (let i = 0; i < options.length; i++) {
        this.options.push({
            value: options[i].value,
            text: options[i].innerText,
            selected: options[i].getAttribute('selected') != null ? options[i].getAttribute('selected') : false
        });
    }
  }

  remove(index, option) {
    this.options[option].selected = false;
    this.selected.splice(index, 1);
    this.onSelectChanged(this.selected.map(i => this.options[i].value));
  }

  select(index, event) {
    if (!this.options[index].selected) {
        this.options[index].selected = true;
        this.options[index].element = event.target;
        this.selected.push(index);
    } else {
        this.selected.splice(this.selected.lastIndexOf(index), 1);
        this.options[index].selected = false
    }
    this.onSelectChanged(this.selected.map(i => this.options[i].value));
  }
}
