up.compiler(".js-address-autocomplete", (el, center) => {
  let autocomplete
  let map
  let marker = null;

  function initAutocomplete() {
    autocomplete = new google.maps.places.Autocomplete(el, {
      types: ["geocode", "establishment"],
    })

    autocomplete.addListener("place_changed", fillInAddress)
  }

  function fillInAddress() {
    var place = autocomplete.getPlace()

    $("#site_latitude").val(place.geometry.location.lat())
    $("#site_longitude").val(place.geometry.location.lng())

    const html_address = place.adr_address
    const address = html_address.match(
      /<span class="street-address">([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\w\d\s-\/,.]*)<\/span>/
    )
    const city = html_address.match(
      /<span class="locality">([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\w\d\s-\/,.]*)<\/span>/
    )
    const state = html_address.match(
      /<span class="region">([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\w\d\s-\/,.]*)<\/span>/
    )
    const zip = html_address.match(
      /<span class="postal-code">([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF\w\d\s-\/,.]*)<\/span>/
    )
    const country = place.address_components.find((c) =>
      c.types.includes("country")
    ).short_name

    $("#site_address").val(address ? address[1] : "")
    $("#site_city").val(city ? city[1] : "")
    $("#site_state").val(state ? state[1] : "-")
    $("#site_zip").val(zip ? zip[1].split("-")[0] : "")
    $("#site_country").val(country)

    marker.setPosition(place.geometry.location);
    map.panTo(place.geometry.location);
    $("#no-location-warning").hide()

    $("input, select").each(function (i, el) {
      if ($(el).val() !== "") $(el).removeClass("error")
    })
  }

  function initMap(position) {
    map = new google.maps.Map($(".js-address-map")[0], {
      scrollwheel: false,
      center: position,
      zoom: 16,
    })

    var icon = {
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: "#ff5b1f",
      fillOpacity: 1,
      strokeColor: "#fff",
      strokeWeight: 2,
      scale: 8,
    }

    marker = new google.maps.Marker({
      position,
      map,
      icon,
      draggable: true,
    })

    google.maps.event.addListener(marker, "dragend", function (e) {
      $("#site_latitude").val(e.latLng.lat())
      $("#site_longitude").val(e.latLng.lng())
      $("#no-location-warning").hide()
    })
  }

  $.getScript("https://www.google.com/jsapi", () => {
    google.load("maps", "3", {
      other_params:
        "key=" + window._gmaps_key + "&libraries=places",
      callback: () => {
        initAutocomplete()
        if (center.lat && center.lng) {
          initMap(center)
        } else {
          initMap({lat: 36.778259, lng: -119.417931})
        }
      },
    })
  })
})
