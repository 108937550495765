import Glider from "glider-js";
import "glider-js/glider.css";

up.compiler("[data-carousel]", el => {
  new Glider(el, {
    slidesToShow: 1,
    dots: '[data-carousel-dots]',
    draggable: true,
    scrollLock: true,
  })
})
