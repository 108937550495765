import "shared/unpoly_legacy";
import "shared/autosize";
import "shared/toastify";
import "shared/redactor";
import "shared/carousel";
import "shared/image_uploader";
import "alpinejs";
import LetterMap from "../letter_map/legacy_index";
import MultitagsFilter from "../letter_map/multitags_filter";
import '../site_map';
import '../styles/public.css';

window.LetterMap = LetterMap;
window.MultitagsFilter = MultitagsFilter;

up.compiler('#comment_recaptcha', function(element) {
    grecaptcha.render('comment_recaptcha', {
        sitekey: element.dataset.sitekey,
    });
})
